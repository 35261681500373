import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (email && password) {
      console.log('Login successful:', { email, password });
      navigate('/learn');
    } else {
      console.error('Please enter both email and password.');
    }
  };

  return (
    <div id="parent">
      <Navbar />
      <h3 className="text-center mb-5">Login</h3>
      <div className="form-container m-auto">
        <form className="form" id="myForm" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              required 
              name="email" 
              id="email" 
              type="email" 
              placeholder="example@keyprediction.com" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="pass">Password</label>
            <input 
              required 
              name="pass" 
              id="pass" 
              type="password" 
              placeholder="****" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="form-submit-btn">Submit</button>
        </form>
      </div>
    <Footer />
    </div>
  );
}

export default Login;
