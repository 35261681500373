import React, { useEffect, useState } from 'react';
import { Element } from 'react-scroll'; 
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import ImgCard from '../Components/ImgCard/ImgCard';

import headImg from "../Images/KP-Superheader-1.png";
import headImg2 from "../Images/KP-Superheader-2.png";
import IncreaseSalesImg from "../Images/IncreaseSales.png";
import CustomerServiceImg from "../Images/CustomerService.png";
import CostReductionImg from "../Images/CostReduction.png";
import KeyTrendsImg from "../Images/KeyTrends.png";
import DataAnalyticsImg from "../Images/DataAnalytics.png";
import RecommendationImg from "../Images/RecommendationSystems.png"
import ContentGenerationImg from "../Images/ContentGeneration.png";
import ComputerVisionImg from "../Images/ComputerVision.png";
import MLSimg from "../Images/MachineLearningSolutions.png";
import PredictiveImg from "../Images/PredictiveAnalytics.png";
import AIdrivenImg from "../Images/AI-DrivenBusiness.png";
import DecisionsImg from "../Images/Decisions.png";

(function (t, e) {
  "use strict";
  var widget_url = "";
  window.lpTag = window.lpTag || {};
  window.destination = "test";
  window.chatfullscreen = false;
  var r = function (t) {
      try {
          var r = e.head || e.getElementsByTagName("head")[0],
              a = e.createElement("script");
          a.setAttribute("type", "text/javascript"); a.setAttribute("src", t); r.appendChild(a);
      } catch (t) {}
  };

  t.NDSId = "local";
  r("http://localhost:5000/js/chat.js");
})(window, document);
console.log(window.domain);

const Start = () => {
  const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: '',
      phone: ''
  });

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
          ...formData,
          [name]: value
      });
  };

  const handleSubmit = async (e) => {
      e.preventDefault();

      try {
          const response = await fetch('http://localhost:8000/contact/send-form', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(formData)
          });

          if (response.ok) {
              const data = await response.json();
              console.log('Success:', data);
              // Mostrar un mensaje de éxito al usuario
          } else {
              console.error('Error:', response.statusText);
              // Mostrar un mensaje de error al usuario
          }
      } catch (error) {
          console.error('Error:', error);
          // Mostrar un mensaje de error al usuario
      }
  };
  
  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      let currentSection = 'home';
      
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        const scrollPosition = window.scrollY + window.innerHeight / 2;
        
        if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
          currentSection = section.getAttribute('id');
        }
      });
      
      setActiveSection(currentSection);
    };
    
    window.addEventListener('scroll', handleScroll);
    handleScroll(); 

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className='container-fluid px-0'>
      <Navbar activeSection={activeSection} />
      <div className="container-fluid p-0">

        {/* Carousel */}
        <Element name="home" className="section -home" >
          <div className="row p-0 ">
            <div className="col-12 w-100 p-0">
              <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src={headImg} class="d-block w-100" alt="headImg" />
                    <div className="carousel-text">
                      <div className="row carousel-text-position">
                        <div className="col-10 col-lg-12 mb-16">
                          <p className="h2">
                            Introducing KeyPrediction
                          </p>
                          <p className="super-header">
                            Explore AI for your business
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <img src={headImg2} class="d-block w-100" alt="headImg" />
                    <div className="carousel-text">
                      <div className="row carousel-text-position">
                        <div className="col-10 col-lg-12 mb-16">
                          <p className="h2">
                            Supporting new projects
                          </p>
                          <p className="super-header">
                            Machine Learning for you
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Element>
        {/* Carousel */}

        {/* Your business goals */}
        <Element name="businessGoals" className="section">
          <div className="p-0">
            <div className="container">
              <div className="row mb-40">
                <div className="col-12">
                  <p className="h1 -b">
                    What are your business goals?
                  </p>
                  <p className="h4">
                    Strategic Objectives
                  </p>
                </div>
              </div>
              <div className="row card-container ">
                <div className="col-hover col-12 col-md-3 mb-24">
                  <ImgCard 
                    imgSrc={IncreaseSalesImg}
                    altText="Machine Learning Solutions"
                    title="Increase Sales"
                    bodyText="Elevate your sales with strategies that turn every opportunity into a success."
                    buttonClasses="-text -lightbg text-s mt-16" 
                    buttonText="Discover how we can assist you"
                    buttonHref="#get-started"
                  />
                </div>
                <div className="col-hover col-12 col-md-3 mb-24">
                  <ImgCard 
                    imgSrc={CustomerServiceImg}
                    altText="Predictive Analytics"
                    title="Improve Customer Service"
                    bodyText="Deliver exceptional customer service with solutions that understand and anticipate your customers' needs."
                    buttonClasses="-text -lightbg text-s mt-16" 
                    buttonText="Discover how we can assist you"
                    buttonHref="#get-started"
                  />
                </div>
                <div className="col-hover col-12 col-md-3 mb-24">
                  <ImgCard 
                    imgSrc={CostReductionImg}
                    altText="AI-Driven Business Consulting"
                    title="Reduce Costs"
                    bodyText="Optimize your operations and cut costs with smart technology that does more with less."
                    buttonClasses="-text -lightbg text-s mt-16" 
                    buttonText="Discover how we can assist you"
                    buttonHref="#get-started"
                  />
                </div>
                <div className="col-hover col-12 col-md-3 mb-24">
                  <ImgCard 
                    imgSrc={DecisionsImg}
                    altText="AI-Driven Business Consulting"
                    title="Take Better Decisions"
                    bodyText="Enure that your most important business decisions are backed by data."
                    buttonClasses="-text -lightbg text-s mt-16" 
                    buttonText="Discover how we can assist you"
                    buttonHref="#get-started"
                  />
                </div>
              </div>
            </div>
          </div>
        </Element>
        {/* Your bussiness goals */}

        {/* Our Products */}
        <Element name="products" className="section -light">
          <div className="container">
            <div className="row mb-40">
              <div className="col-12">
                <p className="h1 -b dark-color">
                  Looking for the right solution?
                </p>
                <p className="h4 dark-color">
                  Our Products
                </p>
              </div>
            </div>
            <div className="row card-container">
              <div className="col-hover col-12 col-md-4 mb-24">
                <a href='/products'>
                  <ImgCard 
                    imgSrc={KeyTrendsImg}
                    altText="Machine Learning Solutions"
                    title="Trend Spotting"
                    // bodyText="Customer segmentation, personalized recommendations, campaign optimization"
                    // listTitle="Industries"
                    // listItems={[
                    //   { text: "Financial Services" },
                    //   { text: "Retail"},
                    //   { text: "Manufacturing"}
                    // ]}
                  />
                </a>
              </div>
              <div className="col-hover col-12 col-md-4 mb-24">
                <a href='/products'>
                  <ImgCard 
                    imgSrc={DataAnalyticsImg}
                    altText="Predictive Analytics"
                    title="Data Analytics and Business Intelligence"
                    // bodyText="Analyzing large volumes of data to gain insights, make predictions, and support strategic decision-making."
                    // listTitle="Industries"
                    // listItems={[
                    //   { text: "Financial Services" },
                    //   { text: "Retail"},
                    //   { text: "Manufacturing"}
                    // ]}
                  />
                </a>
              </div>
              <div className="col-hover col-12 col-md-4 mb-24">
                <a href='/products'>
                  <ImgCard 
                    imgSrc={RecommendationImg}
                    altText="AI-Driven Business Consulting"
                    title="Recommendation Systems"
                    // listTitle="Industries"
                    // listItems={[
                    //   { text: "Financial Services" },
                    //   { text: "Retail"},
                    //   { text: "Manufacturing"}
                    // ]}
                  />
                </a>
              </div>
              <div className="col-hover col-12 col-md-4 mb-24">
                <a href='/products'>
                  <ImgCard 
                    imgSrc={ContentGenerationImg}
                    altText="AI-Driven Business Consulting"
                    title="Content Generation"
                    // listTitle="Industries"
                    // listItems={[
                    //   { text: "Financial Services" },
                    //   { text: "Retail"},
                    //   { text: "Manufacturing"}
                    // ]}
                  />
                </a>
              </div>
              <div className="col-hover col-12 col-md-4 mb-24 mb-md-160">
                <a href='/products'>
                  <ImgCard 
                    imgSrc={ComputerVisionImg}
                    altText="AI-Driven Business Consulting"
                    title="Computer Vision"
                    // listTitle="Industries"
                    // listItems={[
                    //   { text: "Financial Services" },
                    //   { text: "Retail"},
                    //   { text: "Manufacturing"}
                    // ]}
                  />
                </a>
              </div>
            </div>
          </div>
        </Element>
        {/* Our Products */}

        {/* Services */}
        <Element name="services" className="section">
          <div className="p-0 services-section ">
            <div className="">
              <div className="container">
                <p className="h1 -b">Ready to discover our expertise?</p>
                <p className="h4">Our services</p>
              </div>
            </div>
            <div className="">
              <div className="container">
                <div className="row card-container">
                  <div className="col-hover col-12 col-md-4 mb-24">
                    <ImgCard 
                      imgSrc={MLSimg}
                      altText="Machine Learning Solutions"
                      title="Machine Learning Solutions"
                      bodyText="Harness the power of machine learning to automate and enhance your business processes."
                    />
                  </div>
                  <div className="col-hover col-12 col-md-4 mb-24">
                    <ImgCard 
                      imgSrc={PredictiveImg}
                      altText="Predictive Analytics"
                      title="Predictive Analytics"
                      bodyText="Unlock the best version of your company with data-driven insights."
                    />
                  </div>
                  <div className="col-hover col-12 col-md-4 mb-24">
                    <ImgCard
                      imgSrc={AIdrivenImg}
                      altText="AI-Driven Business Consulting"
                      title="AI-Driven Business Consulting"
                      bodyText="Revolutionize your business strategy with AI-driven insights."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Element>
        {/* Services */}

        {/* Get in touch */}
        <Element name="getInTouch" className="section -light">
          <div className="light-bg p-0">
            <div className="container">
              <div className="row mt-60">
                <div className="col-12">
                  <p className="h1 -b dark-color">
                    Want to Know More?
                  </p>
                  <p className="h4 dark-color">
                    Contact Us Now!
                  </p>
                </div>
              </div>
              <div className="row p-0 mt-24 ">
                <div className="col-12 col-md-6">
                  <div className="row d-flex">
                    <div className="col-12 col-md-6">
                      <label htmlFor="userName" className="form-label">Name</label>
                      <input
                        type="text"
                        className="form-control -input w-100"
                        id="userName"
                        placeholder="Your name"
                        value={formData.name}
                        onChange={handleChange}
                        required 
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="userEmail" className="form-label">E-mail</label>
                      <input
                        type="text"
                        className="form-control -input w-100"
                        id="userEmail"
                        placeholder="your@email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-16">
                      <label htmlFor="userPhone" className="form-label">Phone</label>
                      <input
                        type="text"
                        className="form-control -input w-100"
                        id="userPhone"
                        placeholder="+1 55 55 55 55 55"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 mt-16">
                      <label htmlFor="userMessage" className="form-label">Message</label>
                      <textarea 
                        className="w-100" 
                        name="userMessage" 
                        id="userMessage" 
                        cols="150"
                        rows="10" 
                        placeholder="Your message"
                        value={formData.message}
                        onChange={handleChange}>
                      </textarea>
                    </div>
                    <div className="col-12 mt-24">
                      <button className="btn -primary -lightbg">
                        Send message
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-24 dark-color">
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-8 mb-24 text-s">
                      <p className="h4 -b">
                        Chat with us
                      </p>
                      <p className="body-1">
                        Speak with our team via chat
                      </p>
                      <div className='mt-8'>
                        <button className="btn -text">
                          <i class="fa-solid fa-message mt-4px mr-8"></i>
                          Start live chat
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-md-8 mb-24 text-s">
                      <p className="h4 -b">
                        Call us
                      </p>
                      <p className="body-1">
                        Call our team Mon-Fri from 8am to 5pm. (GMT-6)
                      </p>
                      <div className='mt-8'>
                        <a href="tel:+525550812070">
                          <button className="btn -text text-s align-items-start">
                            <i class="fa-solid fa-phone mt-4px mr-8"></i>
                            +52 55 5081 2070
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="col-12 col-md-8 mb-24 text-s">
                      <p className="h4 -b">
                        Visit us
                      </p>
                      <p className="body-1">
                        Chat with us in person at our HQ!
                      </p>
                      <p className="label disabled-color">
                        (By appointment only)
                      </p>
                      <div className='mt-16'>
                        <p className="body-1 -b">
                          Mexico City, Mexico
                        </p>
                        <a href="https://maps.app.goo.gl/cHmdK3KqAMdWMzWi8">                      
                          <button className="btn -text text-s align-items-start">
                            <i class="fa-solid fa-location-dot mt-4px mr-8"></i>
                            Prolongación Reforma 1190, 05349 Ciudad de México
                          </button>
                        </a>
                      </div>
                      
                      <div className='mt-16'>
                        <p className="body-1 -b">
                          Madrid, Spain
                        </p>
                        <a href="https://maps.app.goo.gl/7dzkyxDg9iHXVUgg8">                      
                          <button className="btn -text text-s align-items-start">
                            <i class="fa-solid fa-location-dot mt-4px mr-8"></i>
                            Paseo de la Castellana 93, 28046 Madrid
                          </button>
                        </a>
                      </div>

                      <div className='mt-16'>
                        <p className="body-1 -b">
                          California, US
                        </p>
                        <a href="https://maps.app.goo.gl/k3WvHmz3a6PAo4YC9">                      
                          <button className="btn -text text-s align-items-start">
                            <i class="fa-solid fa-location-dot mt-4px mr-8"></i>
                            3131 Camino Del Rio N, 92108 California
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Element>
        {/* Get in touch */}

      </div>
      <Footer />
    </div>
  );
}

export default Start;