import React from 'react';
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";

import KeyTrendsImg from "../Images/KeyTrends.png";
import DataAnalyticsImg from "../Images/DataAnalytics.png";
import RecommendationImg from "../Images/RecommendationSystems.png"
import ContentGenerationImg from "../Images/ContentGeneration.png";
import ComputerVisionImg from "../Images/ComputerVision.png";

const Products = () => {
  return (
    <div id="parent">
        <Navbar />
        <div className="container my-5">
        <main className="container">
          <div id="main">
            <div className="mb-5">
              <h3 className="text-center">Our quality products</h3><br />
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">Trend Spotting</h4>
                    <p className="card-text">Identifies market trends to boost sales and strategic decisions.</p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">Business Intelligence Dashboards</h4>
                    <p className="card-text">Transforms data into actionable insights for better decision-making.</p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">Recommendation Systems</h4>
                    <p className="card-text">Personalizes product suggestions to increase sales and engagement.</p>
                  </div>
                </div>
                <div className=" col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">Content Generation</h4>
                    <p className="card-text">Automates personalized content creation for marketing and communications.</p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">Computer Vision</h4>
                    <p className="card-text">Analyzes images and live video for control, safety, and diagnostics.</p>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div id='section1'>
              <div className="mb-5">
                <h3 className="text-center"><b>Lead with value</b></h3>
                <p className="text-center">Understand where the worlds attention is and will be with the power of AI</p>
              </div>
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">1.</h4>
                    <p className="card-text">By identifying emerging market trends companies can stay ahead of competitors and capitalize on new opportunities. It allows businesses to identify underserved segments.</p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">2.</h4>
                    <p className="card-text">The platform provides personalized marketing recommendations, ensuring that customers receive relevant offers, enhancing their overall experience.</p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">3.</h4>
                    <p className="card-text">With real-time insights into consumer behavior and market dynamics, companies can make informed decisions that align with market demands.</p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">4.</h4>
                    <p className="card-text">Optimizing marketing campaigns based on data-driven insights can reduce unnecessary spending, focusing resources on the most effective strategies.</p>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div className="card mb-3">
                  <div className="row g-0">
                    <div className="col-md-4">
                      <img src={KeyTrendsImg} />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5 className="card-title">Trend Spotting</h5>
                        <p className="card-text">This platform leverages AI and machine learning to identify emerging trends and patterns in the marketplace. This service is invaluable for companies aiming to stay ahead of competitors by quickly adapting to new consumer behaviors or market shifts. By analyzing data from sales, marketing campaigns, and social media, the platform offers real-time insights into trending products or services. Additionally, it provides personalized recommendations for marketing strategies and customer engagement, significantly boosting ROI for businesses.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div id='section2'>
              <div className="mb-5">
                <h3 className="text-center"><b>Understand the decision making process</b></h3>
                <p className="text-center">Companies need to elevate the usage of KPI and data to take action</p>
              </div>
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">1.</h4>
                    <p className="card-text">By visualizing key metrics, businesses can identify profitable product lines and compare their offerings with market demands, leading to better pricing and product strategies.
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">2.</h4>
                    <p className="card-text">Customized dashboards allow for real-time monitoring of customer satisfaction metrics, enabling proactive responses to customer needs.</p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">3.</h4>
                    <p className="card-text">Comprehensive data analysis supports strategic decision-making, ensuring that all business choices are backed by data.
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">4.</h4>
                    <p className="card-text">By looking for inefficiencies and cost drivers, dashboards enable companies to streamline operations and reduce unnecessary expenses.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div className="card mb-3">
                  <div className="row g-0">
                    <div className="col-md-4">
                      <img src={DataAnalyticsImg} />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5 className="card-title">Data Analytics and Business Intelligence Dashboards</h5>
                        <p className="card-text">This service creates tailored dashboards that centralize all business intelligence needs, transforming raw data into actionable insights by connecting multiple data sources and identifying key metrics. Customizable dashboards are designed for specific industries, enabling companies to track KPIs, forecast sales, manage finances, or monitor patient outcomes in healthcare. Training sessions and consultative support are provided to ensure clients can effectively use these tools to drive strategic decisions.                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div id='section3'>
              <div className="mb-5">
                <h3 className="text-center"><b>Sell with insight</b></h3>
                <p className="text-center">Find what your clients want to buy next from you</p>
              </div>
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">1.</h4>
                    <p className="card-text">Advanced recommendation systems drive cross-selling and up-selling by suggesting relevant products to customers based on their behavior and preferences.
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">2.</h4>
                    <p className="card-text">Personalizing the user experience ensures that customers receive tailored recommendations, enhancing satisfaction and loyalty.</p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">3.</h4>
                    <p className="card-text">Analyzing user interaction data helps businesses understand what products or content resonates most with their audience, guiding future offerings.</p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">4.</h4>
                    <p className="card-text">Efficient targeting through personalized recommendations reduces the cost of customer acquisition and retention.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div className="card mb-3">
                  <div className="row g-0">
                    <div className="col-md-4">
                      <img src={RecommendationImg} />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5 className="card-title">Recommendation Systems</h5>
                        <p className="card-text">This service builds advanced recommendation systems that personalize the user experience across platforms. By analyzing user behavior, purchase history, and other relevant data, these systems suggest products, content, or services tailored to individual preferences. They significantly boost conversion rates by offering relevant cross-selling and up-selling opportunities. Seamless integration with existing platforms like e-commerce sites or streaming services ensures that the recommendation engine delivers accurate and timely suggestions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div id='section4'>
              <div className="mb-5">
                <h3 className="text-center"><b>Express your ideas</b></h3>
                <p className="text-center">Create significant meaning for your business</p>
              </div>
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">1.</h4>
                    <p className="card-text">By producing high-quality, personalized content quickly, businesses can better engage customers and drive conversions.</p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">2.</h4>
                    <p className="card-text">Tailored content enhances customer interaction, making them feel valued and understood.
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">3.</h4>
                    <p className="card-text">Real-time summaries and insights enable companies to adapt content strategies based on customer feedback and engagement data.</p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">4.</h4>
                    <p className="card-text">Automating content creation reduces the need for extensive manual effort, lowering the overall cost of content production.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div className="card mb-3">
                  <div className="row g-0">
                    <div className="col-md-4">
                      <img src={ContentGenerationImg} />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5 className="card-title">Content Generation</h5>
                        <p className="card-text">The Content Generation service utilizes AI-driven tools to create personalized content for marketing campaigns, social media posts, and e-commerce product descriptions. Ideal for businesses needing high-volume content quickly, this service ensures quality and relevance. By leveraging machine learning algorithms, it generates engaging content that resonates with your target audience. Additionally, it offers real-time summaries and insights from user-generated content or company data, simplifying content strategy management and execution.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div id='section5'>
              <div className="mb-5">
                <h3 className="text-center"><b>Explore with vision</b></h3>
                <p className="text-center">Seek perfection and automation at the best level</p>
              </div>
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">1.</h4>
                    <p className="card-text">In retail, computer vision can identify product placement opportunities that drive sales, and in automotive, it enhances vehicle safety features that attract buyers.</p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">2.</h4>
                    <p className="card-text">In healthcare, computer vision improves diagnostic accuracy, leading to better patient outcomes and satisfaction.
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">3.</h4>
                    <p className="card-text">Quality inspection systems in manufacturing ensure products meet high standards, reducing the risk of costly recalls or customer dissatisfaction.</p>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 text-center p-3">
                    <h4 className="card-title">4.</h4>
                    <p className="card-text">Automated visual inspections reduce the need for manual quality checks, saving time and labor costs in manufacturing.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div className="card mb-3">
                  <div className="row g-0">
                    <div className="col-md-4">
                      <img src={ComputerVisionImg} />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5 className="card-title">Computer Vision</h5>
                        <p className="card-text">This service implements advanced computer vision technology across various industries to address specific challenges. In retail, it enhances visual search and inventory management; in healthcare, it aids in medical diagnosis by analyzing images; in the automotive industry, it improves safety features through real-time object and passenger detection. In manufacturing, it ensures quality control by inspecting products to meet precise standards before shipping. This service covers the development, integration, and support of computer vision systems, ensuring they are fully optimized to meet your business's specific needs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    <Footer />
    </div>
  );
}

export default Products;