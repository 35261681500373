import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";

import python from "../Images/svg/python.svg";
import coursera from "../Images/svg/coursera.svg";
import kaggle from "../Images/svg/kaggle.svg";
import book from "../Images/svg/book.svg";
import website from "../Images/svg/website.svg";

import IncreaseSalesImg from "../Images/IncreaseSales.png";
import CustomerServiceImg from "../Images/CustomerService.png";
import CostReductionImg from "../Images/CostReduction.png";
import RecommendationImg from "../Images/RecommendationSystems.png"
import MLSimg from "../Images/MachineLearningSolutions.png";
import PredictiveImg from "../Images/PredictiveAnalytics.png";
import AIdrivenImg from "../Images/AI-DrivenBusiness.png";

const Learn = () => {

  return (
    <div id="parent">
      <Navbar />
      <main>
        <section className="hero section-hero section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 text-center mx-auto">
                <div className="section-hero-text">
                  <br />
                  <br />
                  <br />
                  <br />
                  <small className="small-title primary">Learning Platform</small>
                  <h1 className="text-white">Basic Program</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="columns-container text-center py-12 projects section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="card h-100 bg-light text-dark">
                  <img src={IncreaseSalesImg} className="card-img-top" alt="Finance" />
                  <div className="card-body">
                    <h5 className="card-title">Lenguajes fundamentales de programación I. Python y CUDA</h5>
                    <p className="card-text">
                      <img src={python} className="card-img-height" alt="Python" /> &nbsp;
                      <img src={coursera} className="card-img-height" alt="Coursera" />
                    </p>
                    <Link to="#" className="btn btn-sm -primary">Open</Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="card h-100 bg-light text-dark">
                  <img src={CustomerServiceImg} className="card-img-top" alt="Logistics" />
                  <div className="card-body">
                    <h5 className="card-title">Plataformas de programación y prototipos. Colab y Matlab/Octave</h5>
                    <p className="card-text">
                      <img src={kaggle} className="card-img-height" alt="Kaggle" />
                    </p>
                    <Link to="#" className="btn btn-sm -primary">Open</Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="card h-100 bg-light text-dark">
                  <img src={CostReductionImg} className="card-img-top" alt="Imaging" />
                  <div className="card-body">
                    <h5 className="card-title">Matemáticas de Machine Learning. Álgebra lineal, probabilidad y estadística.</h5>
                    <p className="card-text">
                      <img src={kaggle} className="card-img-height" alt="Kaggle" />
                    </p>
                    <Link to="#" className="btn btn-sm -primary">Open</Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="card h-100 bg-light text-dark">
                  <img src={RecommendationImg} className="card-img-top" alt="Manufacture" />
                  <div className="card-body">
                    <h5 className="card-title">Redes Neuronales Artificiales. Perceptrón, Redes Neuronales de una y varias capas</h5>
                    <p className="card-text">
                      <img src={kaggle} className="card-img-height" alt="Kaggle" />
                    </p>
                    <Link to="#" className="btn btn-sm -primary">Open</Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="card h-100 bg-light text-dark">
                  <img src={MLSimg} className="card-img-top" alt="Retail" />
                  <div className="card-body">
                    <h5 className="card-title">Algoritmos de optimización I.</h5>
                    <p className="card-text">
                      <img src={kaggle} className="card-img-height" alt="Kaggle" />
                    </p>
                    <Link to="#" className="btn btn-sm -primary">Open</Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="card h-100 bg-light text-dark">
                  <img src={PredictiveImg} className="card-img-top" alt="Marketing" />
                  <div className="card-body">
                    <h5 className="card-title">Fundamentos de Machine Learning. Regresiones lineales y polinomiales, aprendizaje supervisado y no supervisado.</h5>
                    <p className="card-text">
                      <img src={kaggle} className="card-img-height" alt="Kaggle" />
                    </p>
                    <Link to="#" className="btn btn-sm -primary">Open</Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="card h-100 bg-light text-dark">
                  <img src={AIdrivenImg} className="card-img-top" alt="Consulting" />
                  <div className="card-body">
                    <h5 className="card-title">Procesamiento en GPUs.</h5>
                    <p className="card-text">
                      <img src={kaggle} className="card-img-height" alt="Kaggle" />
                    </p>
                    <Link to="#" className="btn btn-sm -primary">Open</Link>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="hero section-hero section-padding" id="section_2">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 text-center mx-auto">
                <div className="section-hero-text">
                <br />
                  <br />
                  <br />
                  <br />
                  <small className="small-title primary">Learning Platform</small>
                  <h1 className="text-white">Advanced Program</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="columns-container text-center py-12 projects section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="card h-100 bg-light text-dark">
                  <img src={IncreaseSalesImg} className="card-img-top" alt="Finance" />
                  <div className="card-body">
                    <h5 className="card-title">Lenguajes fundamentales de programación I. Python y CUDA</h5>
                    <p className="card-text">
                      <img src={python} className="card-img-height" alt="Python" />
                      <img src={coursera} className="card-img-height" alt="Coursera" />
                    </p>
                    <Link to="#" className="btn btn-sm -primary">Open</Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="card h-100 bg-light text-dark">
                  <img src={IncreaseSalesImg} className="card-img-top" alt="Logistics" />
                  <div className="card-body">
                    <h5 className="card-title">Plataformas de programación y prototipos. Colab y Matlab/Octave</h5>
                    <p className="card-text">
                      <img src={kaggle} className="card-img-height" alt="Kaggle" />
                    </p>
                    <Link to="#" className="btn btn-sm -primary">Open</Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="card h-100 bg-light text-dark">
                  <img src={IncreaseSalesImg} className="card-img-top" alt="Imaging" />
                  <div className="card-body">
                    <h5 className="card-title">Matemáticas de Machine Learning. Álgebra lineal, probabilidad y estadística.</h5>
                    <p className="card-text">
                      <img src={kaggle} className="card-img-height" alt="Kaggle" />
                    </p>
                    <Link to="#" className="btn btn-sm -primary">Open</Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="card h-100 bg-light text-dark">
                  <img src={IncreaseSalesImg} className="card-img-top" alt="Manufacture" />
                  <div className="card-body">
                    <h5 className="card-title">Redes Neuronales Artificiales. Perceptrón, Redes Neuronales de una y varias capas</h5>
                    <p className="card-text">
                      <img src={kaggle} className="card-img-height" alt="Kaggle" />
                    </p>
                    <Link to="#" className="btn btn-sm -primary">Open</Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="card h-100 bg-light text-dark">
                  <img src={IncreaseSalesImg} className="card-img-top" alt="Retail" />
                  <div className="card-body">
                    <h5 className="card-title">Algoritmos de optimización I.</h5>
                    <p className="card-text">
                      <img src={kaggle} className="card-img-height" alt="Kaggle" />
                    </p>
                    <Link to="#" className="btn btn-sm -primary">Open</Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="card h-100 bg-light text-dark">
                  <img src={IncreaseSalesImg} className="card-img-top" alt="Marketing" />
                  <div className="card-body">
                    <h5 className="card-title">Fundamentos de Machine Learning. Regresiones lineales y polinomiales, aprendizaje supervisado y no supervisado.</h5>
                    <p className="card-text">
                      <img src={kaggle} className="card-img-height" alt="Kaggle" />
                    </p>
                    <Link to="#" className="btn btn-sm -primary">Open</Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="card h-100 bg-light text-dark">
                  <img src={IncreaseSalesImg} className="card-img-top" alt="Consulting" />
                  <div className="card-body">
                    <h5 className="card-title">Procesamiento en GPUs.</h5>
                    <p className="card-text">
                      <img src={kaggle} className="card-img-height" alt="Kaggle" />
                    </p>
                    <Link to="#" className="btn btn-sm -primary">Open</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Learn;
