/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { useLocation } from 'react-router-dom';

import KP from '../../Images/svg/KP-Logo.svg';
import MLsolution from '../../Images/svg/ML-Solution.svg';


const Navbar = ({ activeSection }) => {
  const [isMainScreen, setIsMainScreen] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setIsMainScreen(location.pathname === '/');
  }, [location]);

  
  return (
    <div className='p-0'>
      <nav className="navbar navbar-expand-lg fixed-top navbar-dark">
        <div className="container-fluid">
          <a className="navbar-brand" href={isMainScreen ? "#home" : "/"}>
            <img src={KP} alt="KP monograma" className="d-inline-block align-text-top navbar-logo" />
            <img src={MLsolution} alt="KP monograma" className="d-inline-block align-text-top navbar-logo d-none-sm" />
          </a>
          
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
            <ul className="navbar-nav align-items-center">
              {isMainScreen ? (
                <>
                  <li className="nav-item">
                    <Link to="home" className={`nav-link ${activeSection === 'home' ? 'active' : ''}`}>
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="businessGoals" className={`nav-link ${activeSection === 'businessGoals' ? 'active' : ''}`}>
                      Your goals
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="products" className={`nav-link ${activeSection === 'products' ? 'active' : ''}`}>
                      Products
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="services" className={`nav-link ${activeSection === 'services' ? 'active' : ''}`}>
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="getInTouch" className={`nav-link ${activeSection === 'getInTouch' ? 'active' : ''}`}>
                      Let's connect
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <a className="nav-link" href="/">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/">
                      Your goals
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/products">
                      Products
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/">
                      Services
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/">
                      Let's connect
                    </a>
                  </li>
                </>
              )}
              <li className="nav-item">
                <a className="nav-link" href="/login">
                  <button type="button" className="btn-sm -primary">Login</button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;