import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Start from './Pages/Start.jsx';
import Login from './Pages/Login';
import Products from './Pages/Products';
import Learn from './Pages/Learn';
import reportWebVitals from './reportWebVitals'; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Start />} />
        <Route path="/login" element={<Login/>} />
        <Route path="/products" element={<Products/>} />
        <Route path="/learn" element={<Learn/>} />
      </Routes>
    </Router>
  </React.StrictMode> 
);

reportWebVitals();