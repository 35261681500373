/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';

import KP from '../../Images/svg/KP-Logo.svg';
import MLsolution from '../../Images/svg/ML-Solution.svg';

function Footer(props) {
  return (
    <footer className="py-4">
      <div className="container mt-24 mb-24">
        <div className="row mt-96 mb-40">
          <div className="col-12 mb-24">
            <img src={KP} alt="KP monograma" class="d-inline-block align-text-top img-40" />
            <img src={MLsolution} alt="KP monograma" class="d-inline-block align-text-top img-40 ml-32" />
          </div>
          <div className="col-12 d-flex align-items-center">
            <button className="btn -icon -xl">
              <i class="fa-brands fa-linkedin"></i>
            </button>
            <p className="h4 mb-0">
              <a href="hello@keyprediction.com">
                support@keyprediction.com
              </a>
            </p>
          </div>
        </div>
        <div className="row mb-40">
          <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-0">
            <p className='h3 -b'>Explore more</p>
            <ul className="list-unstyled">
              <li>
                 <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/products">Products</Link>
              </li>
              <li>
              <Link to="/">Services</Link>
              </li>
              <li>
              <Link to="/">About us</Link>
              </li>
              <li>
              <Link to="/">Get in touch</Link>
              </li>
              <li>
              <Link to="/">Our privacy notice</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-0">
            <p className='h3 -b'>Partners</p>
            <ul className="list-unstyled">
              <li>
                AWS
              </li>
              <li>
                IBM
              </li>
              <li>
                Google
              </li>
              <li>
                Microsoft
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-0">
            <p className='h3 -b'>Industries</p>
            <ul className="list-unstyled">
              <li>
                Banking & Fintech
              </li>
              <li>
                Manufacturing
              </li>
              <li>
                Retail
              </li>
              <li>
                Marketing & Advertising
              </li>
            </ul>
          </div>
          
        </div>
        <div className="text-center">
          <p className='label'>&copy; 2024 KeyPrediction. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;