import React, { useReducer } from "react";

const initialState = (props) => ({
  cardClasses: `card p-0 h-100 mt-24 ${props.cardClasses || ""}`,
  imgSrc: props.imgSrc,
  altText: props.altText || "Default Alt Text",
  title: props.title || "Default Title",
  bodyText: props.bodyText || "",
  listTitle: props.listTitle || "",
  listItems: props.listItems || [],
  buttonClasses: `btn ${props.buttonClasses || ""}`,
  buttonText: props.buttonText || "",
  buttonHref: props.buttonHref || "" 
});

function reducer(state, action) {
  switch (action.type) {
    case "SET_CARD_CLASS":
      return { ...state, cardClass: action.payload };
    case "SET_IMG_SRC":
      return { ...state, imgSrc: action.payload };
    case "SET_ALT_TEXT":
      return { ...state, altText: action.payload };
    case "SET_TITLE":
      return { ...state, title: action.payload };
    case "SET_BODY_TEXT":
      return { ...state, bodyText: action.payload };
    case "SET_LIST_TITLE":
      return { ...state, listTitle: action.payload };
    case "SET_LIST_ITEMS":
      return { ...state, listItems: action.payload };
    case "SET_BUTTON_CLASS":
      return { ...state, buttonClass: action.payload };
    case "SET_BUTTON_TEXT":
      return { ...state, buttonText: action.payload };
    case "SET_BUTTON_HREF":
      return { ...state, buttonHref: action.payload };
    default:
      return state;
  }
}

const ImgCard = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState(props));

  return (
    // <div className="card p-0 animation-card h-100">
    <div className={state.cardClasses}>
      <img
        src={state.imgSrc}
        className="card-img-top"
        alt={state.altText}
      />
      <div className="card-body d-flex align-items-between">
        <div className="row">
          <div className="col-12">
            <p className="h4 -b ">{state.title}</p>
            {state.bodyText && <p className="body-2 card-text">{state.bodyText}</p>}
          </div>
          <div className="col-12">
            {state.listItems.length > 0 && (
              <div className="card-list-section">
                
                {state.listTitle && <p className="body-2 -b mb-4px">{state.listTitle}</p>}
                <ul className="card-list">
                  {state.listItems.map((item, index) => (
                    <li key={index}>
                      <a href={item.href} className="list-link">
                        {item.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="col-12">
              {state.buttonText && state.buttonHref && (
              <button href={state.buttonHref} className={props.buttonClasses}>
                {state.buttonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};


export default ImgCard;
